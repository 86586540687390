import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Creation = ({ name, technologies, children, url }) => {
  return (
    <a href={url} target="_blank">
      <article className="bg-light p-6 rounded mb-6">
        <h3 className="mt-0">{name}</h3>
        {children}

        <div className="flex pt-3 mt-3">
          {technologies.map(t => (
            <div
              key={t}
              className="mr-3 px-2 rounded py-1 bg-primary text-white"
            >
              {t}
            </div>
          ))}
        </div>
      </article>
    </a>
  )
}

const Creations = () => (
  <Layout>
    <SEO title="Creations" />
    <h1>Creations</h1>
    <p>A collection of things I have built / am working on / have worked on</p>

    <Creation
      name={"Toml Gist Resume Generator"}
      technologies={["js", "gatsby", "react"]}
      url={"https://wasab.ai/toml-resume"}
    >
      You pass in a github gist id containing TOML that describes your resume
      and get back a resume in PDF form.
    </Creation>
  </Layout>
)

export default Creations
